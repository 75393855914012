import React, {useContext, useMemo, useCallback, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {Box, Paper, Typography} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {getTutorStudentDashboard, GetTutorStudentDashboardParams} from "@/api/tutor";
import { LineChart } from '@mui/x-charts/LineChart';
import {REGULAR_CLASS_DATE_FORMAT, REGULAR_CLASS_MONTH_FORMAT} from "@models/course";

export default function Dashboard() {

    const { snack, setSnack } = useContext(SnackbarContext);

    const [query, setQuery] = useState<GetTutorStudentDashboardParams>({

    })

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.DASHBOARD, query],
        async () => {
            const data = (await getTutorStudentDashboard(query)).data
            console.log('useQuery')
            console.log(data)
            return data
        }
    )

    const lineChartMonths = (d) => {
        if (!d)
            return []
        return [...new Set(d.dashboard.map(item => item.months).flat().map(m => m.month))].sort()
    }

    // const lineChartXAxis= useMemo(() => {
    //     const r = [
    //         {
    //             id: 'Month',
    //             data: lineChartMonths(data),
    //             // scaleType: 'time',
    //             // valueFormatter: (date) => date.getFullYear().toString(),
    //         },
    //     ]
    //     return r;
    // }, [data])
    //
    const lineChartSeries = useMemo(() => {
        if (!data)
            return []
    //     const r = data.dashboard.map(item => ({
    //         label: item.name,
    //         data: lineChartMonths(data).map(m => {
    //             const month = item.months.find(month => month.month === m)
    //             return month ? month.students : 0
    //         }),
    //     }))
    //     return r;

        return data.dashboard.map(t => ({
            id: t.tutorId,
            label: t.name,
            dataKey: t.tutorId
        }))

    }, [data]);

    const lineChartDataset = useMemo(() => {
        if (!data)
            return []
        return lineChartMonths(data).map(month => {
            const formatted = {
                date: new Date(String(month)),
                ...data.dashboard.reduce((prev, t) => {
                    const monthData = t.months.find(m => m.month === month)
                    prev[t.tutorId] = monthData ? monthData.students : 0
                    return prev
                }, {})
            }
            return formatted
        })
        // return [
        //     { date: new Date('2024/08'), '1': 1 },
        //     { date: new Date('2024/09'), '1': 2 },
        // ]
    }, [data])

    return (
        <>
            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2 }}
                >
                    篩選
                </Typography>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <DateTimePicker label="開始時間"
                                    slotProps={{
                                        textField: {size: "small"},
                                        field: { clearable: true }
                                    }}
                                    value={query.since ? dayjs(Number(query.since)) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, since: newValue ? `${newValue.valueOf()}` : undefined})}/>
                    <Typography component='div' sx={{ mx: 1 }}>-</Typography>
                    <DateTimePicker label="完結時間"
                                    slotProps={{
                                        textField: {size: "small"},
                                        field: { clearable: true }
                                    }}
                                    value={query.until ? dayjs(Number(query.until)) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, until: newValue ? `${newValue.valueOf()}` : undefined})}/>
                </Box>
            </Paper>

            {/*<LineChart*/}
            {/*    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}*/}
            {/*    series={[*/}
            {/*        {*/}
            {/*            label: 'Tutor A',*/}
            {/*            data: [2, 5.5, 2, 8.5, 1.5, 5],*/}
            {/*        },*/}
            {/*        {*/}
            {/*            label: 'Tutor B',*/}
            {/*            data: [3, 4, 8, 1, 2, 5],*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*    width={500}*/}
            {/*    height={300}*/}
            {/*/>*/}

            {/*<LineChart*/}
            {/*    dataset={[*/}
            {/*        { date: new Date('2024/08'), '1': 1 },*/}
            {/*        { date: new Date('2024/09'), '1': 2 },*/}
            {/*    ]}*/}
            {/*    xAxis={[*/}
            {/*        {*/}
            {/*            id: 'Years',*/}
            {/*            dataKey: 'date',*/}
            {/*            scaleType: 'time',*/}
            {/*            valueFormatter: (date: Date) => dayjs(date).format(REGULAR_CLASS_DATE_FORMAT),*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*    series={[*/}
            {/*        {*/}
            {/*            id: 'Tutor A',*/}
            {/*            label: 'Tutor A',*/}
            {/*            dataKey: '1', // Tutor id*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*    width={500}*/}
            {/*    height={300}*/}
            {/*/>*/}


            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <LineChart
                    dataset={lineChartDataset}
                    xAxis={[
                        {
                            id: 'Years',
                            dataKey: 'date',
                            scaleType: 'time',
                            valueFormatter: (date: Date) => dayjs(date).format(REGULAR_CLASS_DATE_FORMAT),
                        },
                    ]}
                    series={lineChartSeries}
                    width={500}
                    height={300}
                />
            </Paper>
        </>
    )
}
