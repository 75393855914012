import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";
import {
    AdminCreateProductOrderPayload,
    ProductOrder,
    ProductOrderFilter,
    UpdateProductOrderPayload
} from "@models/product-order";

/***
 * @GET
 * /product-orders
 */

export type GetProductOrdersParams = ProductOrderFilter

export interface GetProductOrdersRes {
    productOrders: ProductOrder[],
    count?: number
}

export async function getProductOrders(params: GetProductOrdersParams & Partial<Pagination>) {
    return await axios.get<GetProductOrdersRes>('/product-orders', {
        params,
        token: true
    })
}

/***
 * @GET
 * /product-orders/{productOrderId}
 */

export interface GetProductOrderPathParams {
    id: string
}

export interface GetProductOrderRes {
    productOrder: ProductOrder
}

export async function getProductOrder(pathParams: GetProductOrderPathParams) {
    return await axios.get<GetProductOrderRes>(`/product-orders/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /product-orders
 */

export type PostProductOrderBody = AdminCreateProductOrderPayload

export interface PostProductOrdersRes {
    productOrder: ProductOrder
}

export async function postProductOrders(data: PostProductOrderBody) {
    return await axios.post<PostProductOrdersRes>('/product-orders', data, {token: true})
}

/***
 * @PATCH
 * /product-orders/{productOrderId}
 */

export interface PatchProductOrderPathParams {
    id: string
}

export type PatchProductOrderData = UpdateProductOrderPayload

export async function patchProductOrder(pathParams: PatchProductOrderPathParams, data: PatchProductOrderData) {
    return await axios.patch<SuccessRes>(`/product-orders/${pathParams.id}`, data, {token: true})
}

/***
 * @POST
 * /product-orders/{productOrderId}/accept-payment
 */

export interface PostAcceptProductOrderPaymentPathParams {
    id: string
}

export type PostAcceptProductOrderPaymentRes = SuccessRes

export async function postAcceptProductOrderPayment(pathParams: PostAcceptProductOrderPaymentPathParams) {
    return await axios.post<PostAcceptProductOrderPaymentRes>(`/product-orders/${pathParams.id}/accept-payment`, {}, {token: true})
}

/***
 * @POST
 * /product-orders/{productOrderId}/reject-payment
 */

export interface PostRejectProductOrderPaymentPathParams {
    id: string
}

export type PostRejectProductOrderPaymentBody = {
    rejectedReason: string
}

export type PostRejectProductOrderPaymentRes = SuccessRes

export async function postRejectProductOrderPayment(pathParams: PostRejectProductOrderPaymentPathParams, data: PostRejectProductOrderPaymentBody) {
    return await axios.post<PostRejectProductOrderPaymentRes>(`/product-orders/${pathParams.id}/reject-payment`, data, {token: true})
}
