import {IconButton, IconButtonProps, InputAdornment} from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear";
import {FC} from "react";

export type ClearableInputAdornmentProps = {

} & Pick<IconButtonProps, "onClick">

export const ClearableInputAdornment: FC<ClearableInputAdornmentProps> = (props) => {
    return (
       <InputAdornment sx={{ position: "absolute", right: 32 }}
                       position="end">
         <IconButton
           onClick={props.onClick}
         >
           <ClearIcon></ClearIcon>
         </IconButton>
       </InputAdornment>
     )
}