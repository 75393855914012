import React, {FC, useEffect, useMemo, useState} from "react";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";
import {getCourseHomeworks, GetCourseHomeworksParams} from "@/api/homework";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {CourseHomework} from "@models/homework";
import {AutocompleteProps} from "@mui/material/Autocomplete/Autocomplete";
import debounce from 'debounce';
import {Pagination} from "common/models/pagination";

export type CourseHomeworkSelectProps = Omit<AutocompleteProps<string | { id: string; label: string; }, false, false, true>, 'renderInput' | 'options'> & {
  textFieldProps: {
    variant?: TextFieldVariants;
  } & Omit<TextFieldProps, 'variant'>
};

export const CourseHomeworkSelect: FC<CourseHomeworkSelectProps> = (props) => {

  const {
    textFieldProps,
    ...autocompleteProps
  } = props

  const [keyword, setKeyword] = useState<string | null>(null)
  const [courseHomeworkIds, setCourseHomeworkIds] = useState<string[] | null>(null)

  const query: GetCourseHomeworksParams & Partial<Pagination> = {
    ...(keyword !== "" && !isNaN(Number(keyword)) && {courseId: Number(keyword)}),
    // ...(!!courseHomeworkIds && {courseHomeworkIds})
  }

  const { data, refetch, isLoading } = useQuery(
    [QUERY_KEY.HOMEWORK_LIST, query],
    async () => {
      if (!Object.values(query).length)
        return {
          homeworks: [] as CourseHomework[]
        }
      return (await getCourseHomeworks(query)).data
    }
  )

  const [courseHomeworksLookup, setCourseHomeworksLookup] = useState<{[key:string]: CourseHomework}>({})
  useEffect(() => {
    setCourseHomeworksLookup({
      ...courseHomeworksLookup,
      ...(data && data.homeworks.reduce((l, s) => ({
        ...l,
        [String(s._id)]: s
      }), {}))
    })
  }, [data]);

  const options = useMemo(() => {
    const opts: string[] = []
    const value = autocompleteProps.value as string
    if (value)
      opts.push(value)
    if (!keyword)
      return opts
    return [
      ...opts,
      ...Object.values(courseHomeworksLookup).map((courseHomework) => String(courseHomework._id))
    ]
  }, [courseHomeworksLookup, autocompleteProps])

  useEffect(() => {
    if (autocompleteProps.value)
      setCourseHomeworkIds([autocompleteProps.value as string])
    else
      setCourseHomeworkIds(null)
  }, [autocompleteProps.value])

  const inputChanged = debounce((newInputValue) => setKeyword(newInputValue), 500);

  return (
    <Autocomplete
      {...autocompleteProps}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="沒有相關功課"
      onInputChange={(event, newInputValue) => {
        inputChanged(newInputValue)
      }}
      getOptionLabel={(option) => {
        const courseHomework = courseHomeworksLookup[typeof option === 'string' ? option : option.id]
        return courseHomework ? `[${courseHomework._id}] ${courseHomework.courseId} ${courseHomework.title}` : ''
      }}
      options={options}
      renderInput={(params) =>
        <TextField {...params} {...textFieldProps} placeholder={'輸入課程ID以搜尋'} />
      }
    />
  )
}
