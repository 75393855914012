import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Link from '@mui/material/Link';
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {Student} from "@models/student";
import {getStudents} from "@/api/student";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {CsvExportButton} from "@/components/CsvExportButton";
import { ProductOrder } from "@models/product-order";
import {getProductOrders} from "@/api/product-order";
import {orderStatusUI, ReceiptRecord} from "@models/order";
import {ModalBodyBox} from "@/components/ModalBodyBox";

export default function ProductOrderList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<ProductOrder>[] = [
        {
            field: '_id',
            headerName: '訂單ID',
            sortable: false,
            flex: 1,
            minWidth: 150
        },
        {
            field: 'productItems',
            headerName: '訂單内容',
            sortable: false,
            flex: 1,
            minWidth: 250,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { row.productItems.map(item => (
                            <Box>
                                { item.product.title.zh }
                            </Box>
                        )) }
                    </Box>
                )
            }
        },
        {
            field: 'status',
            headerName: '狀態',
            sortable: false,
            flex: 1,
            minWidth: 80,
            valueGetter: ({ row }) => orderStatusUI[row.status].title
        },
        {
            field: 'firstName',
            headerName: '學生名字',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => (studentLookup[row.studentId] ? studentLookup[row.studentId].firstName : "-")
        },
        {
            field: 'lastName',
            headerName: '學生姓氏',
            sortable: false,
            flex: 1,
            minWidth: 180,
            valueGetter: ({ row }) => (studentLookup[row.studentId] ? studentLookup[row.studentId].lastName : "-")
        },
        {
            field: 'createdAt',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 120,
            valueGetter: ({ row }) => dayjs(row.createdAt).format('DD/MM/YYYY')
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        {
                            row.receiptRecords && row.receiptRecords.length && (
                                <IconButton
                                  color='primary'
                                  size='small'
                                  sx={{ ml: 0.5 }}
                                  onClick={() => clickShowReceipts(row)}
                                >
                                    <ReceiptIcon></ReceiptIcon>
                                </IconButton>
                            )
                        }

                        <IconButton
                            color='primary'
                            size='small'
                            sx={{ ml: 0.5 }}
                            component={RouterLink}
                            to={`/product-orders/edit/${row._id}`}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true,
        ...(!!keyword && {keyword}),
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.PRODUCT_ORDER_LIST, query],
        async () => {
            return (await getProductOrders(query)).data
        }
    )

    const productOrderList = useMemo<ProductOrder[]>(() => {
        if (!data)
            return []
        return data.productOrders.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    const [studentLookup, setStudentLookup] = useState<{[key: string]: Student}>({})
    useEffect(() => {
        getStudents({
            studentIds: [...new Set(productOrderList.map(o => o.studentId))]
        })
            .then(res => {
                const lookup = res.data.students.reduce((l, student) => {
                    l[student._id] = student
                    return l
                }, {} as {[key: string]: Student})
                setStudentLookup(lookup)
            })
            .catch(e => {

            })
    }, [productOrderList]);

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
    };

    const [showingReceipts, setShowingReceipts] = useState<ReceiptRecord[]>([])
    const clickShowReceipts = (row: ProductOrder) => {
        setShowingReceipts(row.receiptRecords || []);
    };

    return (
        <>
            <Button variant="contained" component={RouterLink} to="/product-orders/add">
                新增
            </Button>

            <CsvExportButton sx={{ml: 2}}
                             disabled={selectedRows.length === 0}
                             fileName={"productOrders"}
                             header={[]}
                             rows={productOrderList.filter(o => selectedRows.includes(o._id)).map(o => ([
                                 o._id,
                                 o.productItems.map(item => item.product._id?.toString() || "").join(","),
                                 o.price.toString(),
                                 studentLookup[o.studentId] ? studentLookup[o.studentId].firstName : "-",
                                 studentLookup[o.studentId] ? studentLookup[o.studentId].lastName : "-",
                                 dayjs(o.createdAt).format('DD/MM/YYYY')
                             ]))}/>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={productOrderList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChange}
            />

            <Modal
              open={showingReceipts.length !== 0}
              onClose={() => setShowingReceipts([])}
            >
                <ModalBodyBox sx={{width: 800}}>
                    <Typography variant="h6" component="h2">
                        付款證明記錄
                    </Typography>
                    <Box>
                        <DataGrid
                          sx={{
                              mt: 2,
                              backgroundColor: 'white'
                          }}
                          rows={showingReceipts.map((r, index) => ({id: index, ...r}))}
                          columns={ [
                              {
                                  field: 'imageUrl',
                                  headerName: '付款證明',
                                  sortable: false,
                                  flex: 1,
                                  minWidth: 200,
                                  renderCell: ({ row }) => {
                                      return (
                                        <>
                                            <Link href={row.imageUrl} target="_blank">{row.imageUrl}</Link>
                                        </>
                                      )
                                  }
                              },
                              {
                                  field: 'rejectedReason',
                                  headerName: '拒絕原因',
                                  sortable: false,
                                  flex: 1,
                                  minWidth: 200
                              },
                          ]}

                          disableColumnFilter
                          disableColumnMenu
                          disableColumnSelector
                          disableRowSelectionOnClick
                        />
                    </Box>
                    {/*<Box sx={{mt: 2}}>*/}
                    {/*    <Button*/}
                    {/*      type="submit"*/}
                    {/*      variant="contained"*/}
                    {/*      {...confirmButtonProps}>*/}
                    {/*        { confirmButtonTitle || '確認' }*/}
                    {/*    </Button>*/}
                    {/*    <Button onClick={onClose}>*/}
                    {/*        { cancelButtonTitle || '取消' }*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                </ModalBodyBox>
            </Modal>
        </>
    )
}